import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Section1ColWhite, Section1ColGray, Wrapper, SectionH2, SectionParag } from "../styles/style.js";

const BigButton = styled(Link)`
  display: block;
  background-color: orange;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 15px 20px;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
`;

const SectionBottomCta = ({ grayBackground, city }) => {
  return (
    <div>
      {grayBackground
        ?
        <Section1ColGray>
          <Wrapper>
            <SectionH2>Ready to get an Amazing Limousine experience?</SectionH2>
            <SectionParag>
              When choosing Amazing Limousines for your next special {city ? `event in ${city},` : "event,"} rest assured YOU are the star and we will stop at nothing to make your experience spectacular.
            </SectionParag>
            <BigButton to="/quote">Get Free Service Quote</BigButton>
          </Wrapper>
        </Section1ColGray>
        :
        <Section1ColWhite>
          <Wrapper>
            <SectionH2>Ready to get an Amazing Limousine experience?</SectionH2>
            <SectionParag>
              When choosing Amazing Limousines for your next special {city ? `event in ${city},` : "event,"} rest assured YOU are the star and we will stop at nothing to make your experience spectacular.
            </SectionParag>
            <BigButton to="/quote">Get Free Service Quote</BigButton>
          </Wrapper>
        </Section1ColWhite>
      }
    </div>
  );
}

export default SectionBottomCta;