import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";
import SectionBottomCta from "../components/SectionBottomCta.js";

import { Wrapper, SectionH2, SectionParag, SectionHeaderWhite, Section3Col, Columns, SectionH3 } from "../styles/style.js";

const ServicesPage = () => (
  <Layout>
    <Seo
      title="Our Services"
    />

    <div className="relative z-0" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.9",
          height: 500,
        }}
        alt="limo services"
        src="../img/header-services-bg.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 max-w-screen-md mx-auto">
          <div className="text-white text-5xl md:text-7xl font-extrabold text-center">Amazing Services</div>
        </div>
      </div>
    </div>

    <SectionHeaderWhite>
      <Wrapper>
        <SectionH2>Our Packages</SectionH2>
        <SectionParag>
          Amazing Limousines has helped more than 30,000 clients over the past few decades, can go to every airport, have been to every concert and venue and has helped celebrate every occasion with our clients. A few packages we offer include:
        </SectionParag>
        <SectionParag>
          • Airport Drop Off and Pick Ups • Anniversaries • Award Ceremonies • Bachelor/Bachelorette Parties • Birthdays • Casino Nights • Charters • Concerts • Conventions • Corporate Functions • Dinner with Friends • Engagements • Funerals • Graduations • Homecomings • Night Out • Proms • Proposals • Quinceaneras • Sightseeing • Special Events • Sporting Events • Theatres • Vegas Trips • Weddings • Wine Tours • And so many more...
        </SectionParag>
        <SectionParag>
          Select from our services below, request a quote, and a representative will contact you at their earliest convenience to share pricing and book your reservation. Thank you for trusting Amazing Limousines!
        </SectionParag>
      </Wrapper>
    </SectionHeaderWhite>

    <Section3Col>
      <Columns>
        <StaticImage
          src="../img/section-services-anniversary.jpg"
          alt="anniversary limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Anniversaries</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-bachelor.jpg"
          alt="bachelor bachelorette limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Bachelor/Bachelorette Parties</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-birthdays.jpg"
          alt="birthday limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Birthdays</SectionH3>
      </Columns>
    </Section3Col>

    <Section3Col>
      <Columns>
        <StaticImage
          src="../img/section-services-concerts.jpeg"
          alt="concert limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Concerts</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-corporate.jpg"
          alt="corporate limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Corporate</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-sightseeing.jpg"
          alt="sightseeing tour limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Sightseeing</SectionH3>
      </Columns>
    </Section3Col>

    <Section3Col>
      <Columns>
        <StaticImage
          src="../img/section-services-sports.jpg"
          alt="sporting events limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Sporting Events</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-weddings.jpg"
          alt="wedding limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Weddings</SectionH3>
      </Columns>
      <Columns>
        <StaticImage
          src="../img/section-services-wine-tour.jpg"
          alt="wine tour limo"
          style={{ marginBottom: `10px` }}
        />
        <SectionH3>Wine Tours</SectionH3>
      </Columns>
    </Section3Col>

    <SectionBottomCta grayBackground />
  </Layout>
);

export default ServicesPage